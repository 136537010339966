.root {
  display: flex;
  gap: var(--ye-spacing-rem-xlarge);
  align-items: center;
  justify-content: center;

  min-height: var(--root-min-height);
}

.content-wrapper {
  padding: var(--ye-spacing-rem-huge);
}

.title {
  margin-bottom: var(--ye-spacing-rem-xlarge);
  font-size: var(--ye-font-size-giant);
  font-weight: var(--ye-font-weight-semibold);
  color: var(--color-ink-l2);
}

.sub-title {
  margin-bottom: var(--ye-spacing-rem-normal);
  font-size: var(--ye-font-size-xlarge);
  font-weight: var(--ye-font-weight-semibold);
  color: var(--color-blue-c2-d2);
}

.text {
  margin-bottom: var(--ye-spacing-rem-xsmall);
  font-size: var(--ye-font-size-normal);
}

.button {
  padding: 0;
  color: var(--color-primary);
}
